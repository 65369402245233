<template>
  <div class="notice-wrap">
    <el-breadcrumb separator="/" class="path">
      <el-breadcrumb-item :to="{ path: '/' }" class="path-home">
        <i class="n el-icon-s-home"></i>{{ $lang('首页') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item class="path-help">{{ $lang('公告') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="notice" v-loading="loading">
      <div class="list-wrap">
        <div v-html="$trans(siteInfo.content)">

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "notice",
  components: {},
  data: () => {
    return {
      noticeList: '',
      loading: true
    };
  },
  computed: {
    ...mapGetters(["siteInfo", "token"])
  },
  created() {
    this.loading = false
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
.notice-wrap {
  background: #ffffff;

  .path {
    padding: 15px 0;
  }
}

.notice {
  background-color: #ffffff;
  min-height: 300px;
  position: relative;
  display: flex;

  .list-wrap {
    padding: 10px 40px;
    border: 1px solid #f1f1f1;
    width: 100%;
    margin-bottom: 10px;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    .notice-title {
      padding: 37px 0 20px 0;
      font-size: 18px;
      border-bottom: 1px dotted #e9e9e9;
    }

    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-of-type {
        border-bottom: initial;
      }

      &:nth-child(2) {
        margin-top: 10px;
      }

      .item {
        font-size: $ns-font-size-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 3px 0;

        &:hover {
          color: $base-color;
        }
      }

      .info {
        display: flex;
        font-size: $ns-font-size-base;

        .time {
          margin-right: 10px;
        }
      }
    }
  }
}

.page {
  text-align: center;
}
</style>
